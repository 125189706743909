<template>
  <div style="padding: 0px">
    <a-row
      style="height: 48px; background: black; padding: 0px 16px"
      align="middle"
      type="flex"
    >
      <a-col><div style="color: white; font-size: 24px">Luoghi</div></a-col>
    </a-row>
    <a-row :gutter="12" style="padding: 0px 12px">
      <a-col :span="inEdit ? 16 : 24" style="padding: 12px">
        <a-row>
          <a-col style="padding: 12px 0">
            <a-button @click="newItem" type="primary">NUOVO</a-button>
          </a-col>
        </a-row>
        <div
          id="table"
          style="
            height: calc(100vh - 140px);
            background: white;
            border: 1px solid #aaaaaa;
          "
        ></div
      ></a-col>
      <a-col :span="inEdit ? 8 : 0">
        <div
          style="
            background: #fafafa;
            padding: 12px;
            box-sizing: border-box;
            overflow-y: scroll;
            height: calc(100vh - 48px);
          "
        >
          <a-row type="flex" align="middle" justify="space-between">
            <a-col><h4>Edita</h4></a-col>
            <a-col><a @click="chiudi">chiudi</a></a-col></a-row
          >

          <a-form
            id="components-form-demo-validate-other"
            :form="form"
            @submit="handleSubmit"
          >
            <a-form-item label="Nome">
              <a-input v-decorator="['name', { initialValue: 'Nome locale' }]">
              </a-input>
            </a-form-item>
            <a-form-item label="Indirizzo">
              <a-input
                v-decorator="['indirizzo', { initialValue: 'Indirizzo' }]"
              >
              </a-input>
            </a-form-item>
            <a-form-item label="Descrizione">
              <a-textarea
                v-decorator="['descrizione', { initialValue: 'Descrizione' }]"
                style="height: 128px"
              >
              </a-textarea>
            </a-form-item>
            <a-form-item label="Città">
              <a-input v-decorator="['citta', { initialValue: 'Città' }]">
              </a-input>
            </a-form-item>
            <a-form-item label="telefono">
              <a-input
                v-decorator="['telefono', { initialValue: '040 332244' }]"
              >
              </a-input>
            </a-form-item>
            <a-form-item label="www">
              <a-input v-decorator="['www', { initialValue: 'www.sito.com' }]">
              </a-input>
            </a-form-item>
            <a-form-item label="facebook">
              <a-input
                v-decorator="['facebook', { initialValue: 'www.facebook.com' }]"
              >
              </a-input>
            </a-form-item>
            <a-form-item label="twitter">
              <a-input
                v-decorator="['twitter', { initialValue: 'www.twitter.com' }]"
              >
              </a-input>
            </a-form-item>
            <a-form-item label="instagram">
              <a-input
                v-decorator="[
                  'instagram',
                  { initialValue: 'www.instagram.com' },
                ]"
              >
              </a-input>
            </a-form-item>
            <!-- <a-form-item label="Select" has-feedback hidden>
              <a-select
                v-decorator="['tipologia', { initialValue: 'Ristorante' }]"
                placeholder="Tripologia"
              >
                <a-select-option value="Pasticceria">
                  Pasticceria
                </a-select-option>
                <a-select-option value="Ristorante">
                  Ristorante
                </a-select-option>
                <a-select-option value="Bar"> Bar </a-select-option>
                <a-select-option value="Servizi"> Servizi </a-select-option>
                <a-select-option value="Shop"> Shop </a-select-option>
                <a-select-option value="Altro"> Altro </a-select-option>
              </a-select>
            </a-form-item> -->
            <a-form-item label="Macro Tipologia" has-feedback>
              <a-select
                mode="multiple"
                v-decorator="['macro_tipologia']"
                placeholder="Macro Tipologia"
              >
                <a-select-option
                  v-for="cat of macro_categories"
                  :key="cat"
                  :value="cat"
                >
                  {{ cat }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="Sotto categorie" has-feedback>
              <a-select
                mode="multiple"
                v-decorator="['sotto_tipologia']"
                placeholder="Sotto categorie"
              >
                <a-select-option
                  v-for="cat of categories"
                  :key="cat.name"
                  :value="cat.name"
                >
                  {{ cat.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="Orari">
              <a-input v-decorator="['orari', { initialValue: '' }]"> </a-input>
            </a-form-item>
            <a-form-item label="Collaborazione attiva">
              <a-switch
                v-decorator="[
                  'collaborazione_attiva',
                  { valuePropName: 'checked', initialValue: false },
                ]"
              />
            </a-form-item>
            <a-form-item label="Descrizione collaborazione">
              <a-textarea
                v-decorator="[
                  'descrizione_collaborazione',
                  { initialValue: 'Descrizione collaborazione' },
                ]"
                style="height: 128px"
              >
              </a-textarea>
            </a-form-item>
            <a-form-item label="Lat">
              <a-input v-decorator="['lat', { initialValue: 44.63 }]"></a-input>
            </a-form-item>

            <a-form-item label="Long">
              <a-input v-decorator="['lng', { initialValue: 13.8 }]" />
            </a-form-item>
            <a-form-item label="Upload" extra="">
              <a-upload
                list-type="picture-card"
                name="logo"
                multiple

                :file-list="fileList"
                :customRequest="uploadfiles"
                @change="handleChange"
              >
                <div v-if="fileList.length < 8">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
            </a-form-item>

            <!-- 
             
               list-type="picture"
              
               <a-form-item label="Slider">
              <a-slider
                v-decorator="['slider']"
                :marks="{
                  0: 'A',
                  20: 'B',
                  40: 'C',
                  60: 'D',
                  80: 'E',
                  100: 'F',
                }"
              />
            </a-form-item>

            <a-form-item label="Radio.Group">
              <a-radio-group v-decorator="['radio-group']">
                <a-radio value="a"> item 1 </a-radio>
                <a-radio value="b"> item 2 </a-radio>
                <a-radio value="c"> item 3 </a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="Radio.Button">
              <a-radio-group v-decorator="['radio-button']">
                <a-radio-button value="a"> item 1 </a-radio-button>
                <a-radio-button value="b"> item 2 </a-radio-button>
                <a-radio-button value="c"> item 3 </a-radio-button>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="Checkbox.Group">
              <a-checkbox-group
                v-decorator="['checkbox-group', { initialValue: ['A', 'B'] }]"
                style="width: 100%"
              >
                <a-row>
                  <a-col :span="8">
                    <a-checkbox value="A"> A </a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox disabled value="B"> B </a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="C"> C </a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="D"> D </a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="E"> E </a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </a-form-item> -->

            <!--  <a-form-item label="Rate">
              <a-rate
                v-decorator="['rate', { initialValue: 3.5 }]"
                allow-half
              />
            </a-form-item>

            <a-form-item
              label="Upload"
              extra="longgggggggggggggggggggggggggggggggggg"
            >
              <a-upload
                v-decorator="[
                  'upload',
                  {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                  },
                ]"
                name="logo"
                action="/upload.do"
                list-type="picture"
              >
                <a-button> <a-icon type="upload" /> Click to upload </a-button>
              </a-upload>
            </a-form-item>

            <a-form-item label="Dragger">
              <div class="dropbox">
                <a-upload-dragger
                  v-decorator="[
                    'dragger',
                    {
                      valuePropName: 'fileList',
                      getValueFromEvent: normFile,
                    },
                  ]"
                  name="files"
                  action="/upload.do"
                >
                  <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p class="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </a-upload-dragger>
              </div>
            </a-form-item> -->

            <a-form-item
              style="width: 100%"
              :wrapper-col="{ span: 24, offset: 0 }"
            >
              <a-button style="width: 100%" type="primary" html-type="submit">
                Salva
              </a-button>
            </a-form-item>
          </a-form>
        </div></a-col
      ></a-row
    >
  </div>
</template>

<script>
const axios = require("axios");
const CATEGORIES = [
  { key: "FOOD", children: [{ key: "PIZZERIA" }, { key: "RISTORANTE" }] },
  { key: "SHOP", children: [{ key: "CAPELLI" }] },
  { key: "SERVICES", children: [{ key: "NEGOZIO" }] },
];

import Tabulator from "tabulator-tables";
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "validate_other" });
  },
  computed: {
    // macro_categories() {
    //   return this.$store.state.categories;
    // },
    // categories() {
    //   return this.$store.state.sub_categories;
    // },
  },
  watch: {
    places() {
      this.table.setData(this.places);
    },
  },
  async mounted() {
    this.macro_categories = (
      await axios.get("/vmuts/api/macro_categories")
    ).data;
    this.categories = (await axios.get("/vmuts/api/sub_categories")).data;
    this.getPlaces();
    this.table = new Tabulator(this.$el.querySelector("#table"), {
      // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
      data: this.places, //assign data to table

      layout: "fitColumns", //fit columns to width of table (optional)
      columns: [
        {
          field: "sotto_tipologia",
          title: "tipologia",
          width: 324,
          formatter(e) {
            if (e.getValue() == undefined) return [];
            else {
              let out = ["<div>"];
              e.getValue().map((d) => {
                out.push(`<span class="mini_badge">${d}</span>`);
              });
              out.push("</div>");

              return out.join("");
            }
          },
          hozAlign: "left",
        },
        {
          width: 192,
          field: "name",
          title: "Nome",
          formatter(e) {
            return `<span style="font-weight:400">${e.getValue()}</span>`;
          },
        },
        { field: "citta", title: "citta", width: 140 },

        { field: "indirizzo", title: "indirizzo" },
        { field: "lat", title: "lat", width: 92, hozAlign: "right" },
        { field: "lng", title: "lng", width: 92, hozAlign: "right" },
        {
          field: "collaborazione_attiva",
          title: "Collab",
          formatter(e) {
            return `${e.getValue() ? "SI" : "NO"}`;
          },
          width: 120,
          hozAlign: "center",
        },
        {
          width: 88,
          field: "",
          title: "Azioni",
          hozAlign: "center",
          cellClick: async function (e, ev) {
            e.stopPropagation();
            await axios.post("/vmuts/api/hideplace", ev.getData());
            this.getPlaces();
          }.bind(this),
          formatter: function () {
            return `<span style="padding:0;margin:0;font-weight:400">ELIMINA</span>`;
          }.bind(this),
        },
      ],
      rowClick: async function (e, row) {
        this.inEdit = true;
        this.currentItem = {
          macro_tipologia: [],
          sotto_tipologia: [],
          upload: [],
          ...row.getData(),
        };

        let fileList = [];

    
        this.fileList = this.currentItem.upload.map((d,i)=>({uid:d.uid=i,...d}));

        console.log("----->",this.fileList);

        this.form.setFieldsValue({
          ...this.currentItem,
          //fileList: fileList,
          lat: +this.currentItem.lat,
          lng:  +this.currentItem.lng,
        });
        //trigger an alert message when the row is clicked
      }.bind(this),
    });
  },
  methods: {
    async getPlaces() {
      this.places = (await axios.get("/vmuts/api/places")).data;
    },
    chiudi() {
      this.inEdit = false;
      this.form.resetFields();
    },
    async upload(file) {
      return new Promise((res, rej) => {
        const form = new FormData();


       
         form.append("uid",Date.now());
          form.append("image", file);
       
        fetch("/vmuts/api/upload", {
          
          method: "POST",
          body: form,
        })
          .then(async (resq) => {
            console.log(resq);
            res(await resq.text());
          })
          .catch((err) => ("Error occured", err));
      });
    },
    uploadfiles({ onSuccess, onError, file }) {
      console.log(file)
      this.upload(file)
        .then((s) => {
          onSuccess(s, s);
        })
        .catch((e) => {
          console.log(e);
          console.log("error");
        });
    },
    updateData() {},
    handleChange({ fileList }) {
      this.fileList = fileList.map((q) => {
        console.log(q.response)
        return {
          ...q,
          thumbUrl: "/vmuts/api/images/" + q.response,
          url: "/vmuts/api/images/" + q.response,
        };
      });
    },
    handleSubmit(e) {
      this.inEdit = false;
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
    
        if (!err) {
          let upload = [];

          this.fileList.map((q) => {
            upload.push({ thumbUrl: q.thumbUrl, url: q.url,});
          });

          console.log(this.fileList)

          values.upload = upload;
          if (values.sotto_tipologia == undefined) values.sotto_tipologia = [];

          let itemToSave = { ...this.currentItem, ...values };

          await axios.post("/vmuts/api/place", itemToSave);
          this.getPlaces();

          if (this.currentItem.key) {
            // this.$db.ref(`/places/${this.currentItem.key}`).update({
            //   sotto_tipologia: [],
            //   macro_tipologia: [],
            //   ...values,
            //   internalId: this.internalId,
            //   updateDate: new Date(),
            //   location: {
            //     type: "Feature",
            //     geometry: {
            //       type: "Point",
            //       coordinates: [+values.lng, +values.lat],
            //     },
            //     properties: {},
            //   },
            // });
          } else {
            // this.$places.push({
            //   descrizione: "",
            //   indirizzo: "",
            //   citta: "",
            //   ...values,
            //   upload: values.upload ? values.upload : [],
            //   internalId: this.internalId,
            //   updateDate: new Date(),
            //   creationDate: new Date(),
            //   location: {
            //     type: "Feature",
            //     geometry: {
            //       type: "Point",
            //       coordinates: [+values.lng, +values.lat],
            //     },
            //     properties: {},
            //   },
            // });
          }
          console.log("Received values of form: ", values);
        }
      });
    },
    normFile(e) {
      console.log("Upload event:", e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },
    newItem() {
      this.form.resetFields();
      this.currentItem = {};
      this.currentItem.key = null;
      this.internalId = new Date().getTime();
      this.fileList=[];
      this.form.setFieldsValue({
        collaborazione_attiva: false,
        macro_tipologia: [],
        sotto_tipologia: [],
        upload: [],
      });
      this.inEdit = true;
    },
    add() {},
  },
  data() {
    return {
      categories: [],
      macro_categories: [],
      places: [],
      defaultFileList: [],
      fileList: [],
      internalId: null,
      inEdit: false,
      currentItem: {
        macro_tipologia: [],
        sotto_tipologia: [],
        collaborazione_attiva: false,
        upload: [],
      },
      labelPosition: "on-border",
    };
  },
};
</script>
<style>
button {
  font-size: 13px;
  padding: 0px 4px;
}
.tabulator-row {
  font-family: "Poppins";
}
.tabulator-row .tabulator-cell {
  padding: 5px !important;
  font-size: 13px;

  border-left: 1px solid #e3e3e3;
}
#table {
  width: 100%;
}
.tabulator-cell[tabulator-field="name"] {
  background: #f2f2f2;
}
.tabulator-col-title {
  font-size: 12px;
  font-weight: 300;
  padding-left: 0;
}
.tabulator-col .tabulator-sortable {
  height: 24px !important;
}
.tabulator-col-content {
  background: #fafafa;
}
.tabulator-header {
  border: 1px solid #e3e3e3 !important;
}
.mini_badge {
  height: 22px;
  font-size: 9px;
  text-transform: uppercase;
  border: 1px solid #999999;
  background: #fafafa;
  color: #474747;
  margin-right: 4px;
  border-radius: 4px;
  padding: 1px 3px;
}
.tabulator {
  border: 0 !important;
  border-bottom: 1px solid #eaeaea !important;
}
</style>
